interface CurrencyFormatOptions {
  style: 'currency'
  currency: 'BRL'
  minimumFractionDigits?: number
  maximumFractionDigits?: number
}

/**
 * Formata um número para o formato de moeda brasileira (BRL)
 * @param value - Valor numérico a ser formatado
 * @param locale - Localização para formatação (padrão: 'pt-BR')
 * @param options - Opções de formatação
 * @returns String formatada no padrão de moeda brasileira
 * @example
 * formatCurrency(12) // 'R$ 12,00'
 * formatCurrency(15.5) // 'R$ 15,50'
 * formatCurrency(1000) // 'R$ 1.000,00'
 * formatCurrency(-1234.56) // '-R$ 1.234,56'
 */
export function formatCurrency(
  value: number | string,
  locale: string = 'pt-BR',
  options: CurrencyFormatOptions = {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
): string {
  let numValue: number

  if (typeof value === 'string') {
    const cleanValue = value.replace(/[^\d.-]/g, '')
    numValue = Number.parseFloat(cleanValue)
  }
  else {
    numValue = value
  }

  if (Number.isNaN(numValue)) {
    return 'R$ 0,00'
  }

  const formatter = new Intl.NumberFormat(locale, options)
  const formatted = formatter.format(numValue)

  return formatted.replace(/\s+/g, ' ').trim()
}
